import { EventJoinerModel } from '@index/models/event-joiner';
import { GthEventItemModel, GthTeamModel, GthUserModel } from '@sentinels/models';
import { GthUnregisteredUserModel } from '@sentinels/models/unregistered-user';

export enum CurrentState {
    Loading = 'loading',
    Pending = 'pending',
    Success = 'success',
    Error = 'error',
}

export interface JoinerUserMapper {
    users?: GthUserModel[] | GthUnregisteredUserModel[],
    joiners?: EventJoinerModel[] }

export interface EventInfoState {
    event: GthEventItemModel | null;
    state: CurrentState;
    error?: unknown;
    joinersUsers: JoinerUserMapper;
    teamId: string;
    team?: GthTeamModel;
    isBottomCollapsed: boolean;
}

export const eventInfoInitialState: EventInfoState = {
    event: null,
    teamId: '',
    state: CurrentState.Pending,
    joinersUsers: {},
    isBottomCollapsed: false,
  };
